import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  
  static targets = ['landCost', 'profServices', 'stampDuty', 'totalCost', 'rentalValue' ]

   connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.landCostTarget, autoNumericOptionsVND)
    new AutoNumeric(this.profServicesTarget, autoNumericOptionsVND)
    new AutoNumeric(this.stampDutyTarget, autoNumericOptionsVND)
    new AutoNumeric(this.totalCostTarget, autoNumericOptionsVND)
    new AutoNumeric(this.rentalValueTarget, autoNumericOptionsVND)
  }
  
  updateTotalCost () {
    const totalCost = this.landCost() + this.profServices() + this.stampDuty()
    AutoNumeric.getAutoNumericElement(this.totalCostTarget).set(totalCost)
  }

  landCost () {
    return AutoNumeric.getAutoNumericElement(this.landCostTarget).getNumber()
  }

  stampDuty () {
    return AutoNumeric.getAutoNumericElement(this.stampDutyTarget).getNumber()
  }

  profServices () {
    return AutoNumeric.getAutoNumericElement(this.profServicesTarget).getNumber()
  }

  rentalValue () {
    return AutoNumeric.getAutoNumericElement(this.rentalValueTarget).getNumber()
  }


  totalCost () {
    return AutoNumeric.getAutoNumericElement(this.totalCostTarget).getNumber()
  }
}