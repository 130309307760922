import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['amount', 'grossDevCost', 'grossDevValue', 'loanValue']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.amountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.grossDevCostTarget, autoNumericOptionsVND)
    new AutoNumeric(this.grossDevValueTarget, autoNumericOptionsVND)
  }

  updateLoanValue () {
    const loanValue = Number(this.amount() / this.grossDevValue() * 100)
    this.loanValueTarget.value = Number(loanValue).toFixed(0)+'%'
  }

  loanValue () {
    return parseInt(this.loanValue.value || 0)
  }

  amount () {
    return AutoNumeric.getAutoNumericElement(this.amountTarget).getNumber()
  }

  grossDevValue () {
    return AutoNumeric.getAutoNumericElement(this.grossDevValueTarget).getNumber()
  }


  unformatAmountFields () {
    AutoNumeric.getAutoNumericElement(this.amountTarget).formUnformat()
    AutoNumeric.getAutoNumericElement(this.grossDevCostTarget).formUnformat()
    AutoNumeric.getAutoNumericElement(this.grossDevValueTarget).formUnformat()
  }
  
  amount () {
    return AutoNumeric.getAutoNumericElement(this.amountTarget).getNumber()
    return AutoNumeric.getAutoNumericElement(this.grossDevCostTarget).getNumber()
    return AutoNumeric.getAutoNumericElement(this.grossDevValueTarget).getNumber()
  }
    
}

