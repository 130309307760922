import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['loanAmount', 'assetCost']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

  new AutoNumeric(this.assetCostTarget, autoNumericOptionsVND)
  new AutoNumeric(this.loanAmountTarget, autoNumericOptionsVND)
  }
  
  amount () {
    return AutoNumeric.getAutoNumericElement(this.assetCostTarget).getNumber()
    return AutoNumeric.getAutoNumericElement(this.loanAmountTarget).getNumber() 
  }
}