import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['workingCapitalLoanSecurity', 'workingCapitalLoanSecurityOther', 'totalSecurityValue']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.workingCapitalLoanSecurityTarget, autoNumericOptionsVND)
    new AutoNumeric(this.workingCapitalLoanSecurityOtherTarget, autoNumericOptionsVND)
    new AutoNumeric(this.totalSecurityValueTarget, autoNumericOptionsVND)
  }
  
  workingCapitalLoanSecurity () {
    return AutoNumeric.getAutoNumericElement(this.workingCapitalLoanSecurityTarget).getNumber()
  }

  workingCapitalLoanSecurityOther() {
    return AutoNumeric.getAutoNumericElement(this.workingCapitalLoanSecurityOtherTarget).getNumber()
  }

  totalSecurityValue () {
    return AutoNumeric.getAutoNumericElement(this.totalSecurityValueTarget).getNumber()
  }

  updateTotalSecurityValue () {
    const totalSecurityValue = this.workingCapitalLoanSecurity() + this.workingCapitalLoanSecurityOther()
    AutoNumeric.getAutoNumericElement(this.totalSecurityValueTarget).set(totalSecurityValue)
  }

}
