import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['amount', 'grossDevValue', 'loanValue']


  updateLoanValue () {
    const loanValue = ((this.amount / this.grossDevValue) * 100)
    this.loanValueTarget.value = Number(loanValue).toFixed(0);
  }

  amount () {
    return AutoNumeric.getAutoNumericElement(this.amountTarget).getNumber()
  }

  grossDevValue () {
    return AutoNumeric.getAutoNumericElement(this.grossDevValueTarget).getNumber()
  }

  loanValue () {
    return parseInt(this.loanValue.value || 0)
  }
    
}
