import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['amount', 'propertyPurchasePrice']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }
    
    new AutoNumeric(this.amountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.propertyPurchasePriceTarget, autoNumericOptionsVND)
  }
  
  amount () {
    return AutoNumeric.getAutoNumericElement(this.propertyPurchasePriceTarget).getNumber()

  }
    
}
