import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['loanSecurity', 'loanSecurityOther', 'totalSecurityValue', 'devValue', 'devValueWarning', 'devValueSuccess']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.devValueTarget, autoNumericOptionsVND)
    new AutoNumeric(this.loanSecurityTarget, autoNumericOptionsVND)
    new AutoNumeric(this.loanSecurityOtherTarget, autoNumericOptionsVND)
    new AutoNumeric(this.totalSecurityValueTarget, autoNumericOptionsVND)

  }

  unformatAmountFields () {
    AutoNumeric.getAutoNumericElement(this.loanSecurityTarget).formUnformat()

  }
  
  devValue () {
    return AutoNumeric.getAutoNumericElement(this.devValueTarget).getNumber()
  }

  loanSecurity () {
    return AutoNumeric.getAutoNumericElement(this.loanSecurityTarget).getNumber()
  }

  loanSecurityOther () {
    return AutoNumeric.getAutoNumericElement(this.loanSecurityOtherTarget).getNumber()
  }

  totalSecurityValue () {
    return AutoNumeric.getAutoNumericElement(this.totalSecurityValueTarget).getNumber()
  }

  updateTotalSecurityValue () {
    const totalSecurityValue = this.loanSecurity() + this.loanSecurityOther()
    AutoNumeric.getAutoNumericElement(this.totalSecurityValueTarget).set(totalSecurityValue)
  }

  devValueWarning () {
    if (this.totalSecurityValue() < this.devValue()) {
      this.devValueWarningTarget.style.display = "inline-block"
    } else {
      this.devValueWarningTarget.style.display = "none"
    }
  }

  devValueSuccess () {
    if (this.totalSecurityValue() > this.devValue()) {
      this.devValueSuccessTarget.style.display = "inline-block"
    } else {
      this.devValueSuccessTarget.style.display = "none"
    }
  }
    
}