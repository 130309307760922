import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['rentalValue']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      selectNumberOnly           : true,
      modifyValueOnWheel         : false,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.rentalValueTarget, autoNumericOptionsVND)
  }

  unformatAmountFields () {
    AutoNumeric.getAutoNumericElement(this.rentalValueTarget).formUnformat()
  }
  
  amount () {
    return AutoNumeric.getAutoNumericElement(this.rentalValueTarget).getNumber()

  }
    
}
