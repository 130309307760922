import $ from 'jquery';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

document.addEventListener("turbolinks:load", () => {
  tippy(document.querySelectorAll('[data-tippy-content]'))
})


//time limit for flash notice

$(document).ready(function(){
  setTimeout(function(){
    $('#notice_wrapper').fadeOut();
  }, 3000);
})



//replaces fields in Loan Security form depending on property type chosen

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('security');
  if (element) {
     $('#security').change((e) => {
        showFields(e.target.value)
      });

      showFields($('#security').val());

      function showFields(val) {
        if (val == 'other') {
          $('#property-fields').eq(0).hide();
          $('#other-fields').eq(0).fadeIn();
        }
        else {
          $('#property-fields').eq(0).fadeIn();
          $('#other-fields').eq(0).hide();
        }  
      }
   }
});




//replaces form field depending project type chosen

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('construction-type');
  if (element) {
    $('#construction-type').change((e) => {
      if (e.target.value == 'construction') {
        $('#investment-field').eq(0).hide().prop('disabled', true);
        $('#construction-field').eq(0).show().prop('disabled', false);
        $('#mortgage-joint-name-field').eq(0).hide().prop('disabled', true);
      }
      else {
        $('#construction-field').eq(0).hide().prop('disabled', true);
      }
      if (e.target.value == 'investment') {
        $('#investment-field').eq(0).show().prop('disabled', false);
        $('#mortgage-joint-name-field').eq(0).hide().prop('disabled', true);
      }
      else {
        $('#investment-field').eq(0).hide().prop('disabled', true);
      }
      if (e.target.value == 'home loan') {
        $('#mortgage-field').eq(0).show().prop('disabled', false);
        $('#insurance-field').eq(0).show().prop('disabled', false);
        $(".mpp-btn").attr('disabled', false)
      }
      else {
        $('#mortgage-field').eq(0).hide().prop('disabled', true);
        $('#insurance-field').eq(0).hide().prop('disabled', true);
        $(".mpp-btn").attr('disabled', true)
        $(".joint-applicant").attr('disabled', true)
      }
      if (e.target.value == 'working capital') {
        $('#mortgage-joint-name-field').eq(0).hide().attr('disabled', true);
      }
      if (e.target.value == 'other(specify)') {
        $('#mortgage-joint-name-field').eq(0).hide().prop('disabled', true);
      }
      if (e.target.value == 'site acquisition') {
        $('#mortgage-joint-name-field').eq(0).hide().prop('disabled', true);
      }
      if (e.target.value == 'asset finance') {
        $('#mortgage-joint-name-field').eq(0).hide().prop('disabled', true);
        $('#loan-label').eq(0).hide();
        $('#credit-label').eq(0).show();
      }
      else {
        $('#credit-label').eq(0).hide();
        $('#loan-label').eq(0).show();
      }  
   });
   }
});

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('mortgage-joint');
  if (element) {
    $('#mortgage-joint').change((e) => {
      if (e.target.value == 'Joint') {
        $('#mortgage-joint-name-field').eq(0).show().prop('disabled', false);
        $(".joint-applicant").attr('disabled', false)
      } 
      else {
        $('#mortgage-joint-name-field').eq(0).hide().prop('disabled', true);
        $(".joint-applicant").attr('disabled', true)
      }
   });
  }
});

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('borrower-type');
  if (element) {
    $('#borrower-type').change((e) => {
        showFields(e.target.value)
      });

      showFields($('#borrower-type').val());

      function showFields(val) {
      if (val == 'Personal') {
        $('#company-name').eq(0).hide().prop('disabled', true);
        $('#date-of-birth').eq(0).show().prop('disabled', false);
      }
      else {
        $('#company-name').eq(0).show().prop('disabled', false);
        $('#date-of-birth').eq(0).hide().prop('disabled', true);
      }
      if (val == 'Sole Trader') {
        $('#company-name').eq(0).hide().prop('disabled', true);
        $('#date-of-birth').eq(0).show().prop('disabled', false);
        $('#business-start-date').eq(0).show().prop('disabled', false);
      }
      else {
        $('#business-start-date').eq(0).hide().prop('disabled', true);
      }
      if (val == 'Limited Company') { 
        $('#business-start-date').eq(0).show().prop('disabled', false);
      }
    }
   }
 });


//user image previews

document.addEventListener('turbolinks:load', () => {
  var preview = $(".upload-preview img");
    $(".file").change(function(event){
      var input = $(event.currentTarget);
      var file = input[0].files[0];
      var reader = new FileReader();
      var image_base64;
      reader.onload = function(e){
        image_base64 = e.target.result;
        preview.attr("src", image_base64);
        };
    reader.readAsDataURL(file);
  });
});



document.addEventListener('turbolinks:load', () => {
  $(document).ready(function(){
    tippy('#project-popup', {
      theme: 'loanview-form',
    content: 'Start by letting us know the type of project for which the loan is being applied for'  
    });
    tippy('#construction-popup', {
      theme: 'loanview-form',
      content: 'Choose your construction category, there are four to choose from',
    });
    tippy('#investment-popup', {
      theme: 'loanview-form',
      content: 'Choose your investment category, there are five to choose from',
    });
    tippy('#loan-amount-popup', {
      theme: 'loanview-form',
      content: 'The loan amount you are requesting',
    });
    tippy('#location-popup', {
      theme: 'loanview-form',
      content: 'A more accurate location may help the lender assess the application',
    });
    tippy('#loan-amount-populated-popup', {
      theme: 'loanview-form',
      content: 'Please ensure loan amount requested is accurate',
    });
    tippy('#credit-amount-populated-popup', {
      theme: 'loanview-form',
      content: 'Please ensure credit amount requested is accurate',
    });
    tippy('#total-cost-popup', {
      theme: 'loanview-form',
      content: 'Your total cost is calculated as Land Cost + Stamp Duty + Professional Services, please enter your own figure if applicable',
    });
    tippy('#total-site-cost-popup', {
      theme: 'loanview-form',
      content: 'Your Total Site Cost is calculated as Site Cost + Stamp Duty + Professional Services, please enter your own figure if applicable',
    });
    tippy('#equity-popup', {
      theme: 'loanview-form',
      content: 'Your Equity is calculated as Property Purchase Price (value) - Loan Amount applied for',
    });
    tippy('#repayment-popup', {
      theme: 'loanview-form',
      content: 'Please indicate the source of repayment of the loan from the following options',
    });
    tippy('#eircode-popup', {
      theme: 'loanview-form',
      content: 'To retrieve your Eircode visit www.eircode.ie',
    });
    tippy('#total-security-popup', {
      theme: 'loanview-form',
      content: 'Your Total Security Value is your Estimated Property Security Value + your Estimated Value for Other Security',
    });
    tippy('#loan-value-total-security-popup', {
      theme: 'loanview-form',
      content: 'Your Loan to Value is the Loan Amount against the Total Est Security Value as a percentage',
    });
    tippy('#seven-day-count', {
      theme: 'loanview-form',
      content: 'New Applications, last 7 days',
    });
    tippy('#total-spend-popup', {
      theme: 'loanview-form',
      content: 'Total Spend = Land Cost + Professional Fees + Stamp Duty + Gross Development Cost',
    });
    tippy('#total-spend-industrial-popup', {
      theme: 'loanview-form',
      content: 'Total Spend = Site Cost + Professional Fees + Stamp Duty + Gross Development Cost',
    });
  });
});


document.addEventListener('turbolinks:load', () => {
  $('a[href*=\\#ourCompany]').on('click', function(event){     
      event.preventDefault();
      $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
  });
});


document.addEventListener('turbolinks:load', () => {
  $('#sidebarShow').on('click', function (event) {
    event.preventDefault();
    $('#mobile-sidebar, #content').toggleClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });
});


$(document).click(function(e) {
  var sidebar = $("#mobile-sidebar, #sidebarShow");
  if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {
    sidebar.addClass('active')
  }
});
