import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['amount', 'equity', 'propertyPrice', 'maxLoan', 'loanValue', 'turnRed', 'warning', 'success', 'grossIncome', 'limitField']

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '€ ',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      modifyValueOnWheel         : false,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.equityTarget, autoNumericOptionsVND)
    new AutoNumeric(this.amountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.propertyPriceTarget, autoNumericOptionsVND)
    new AutoNumeric(this.grossIncomeTarget, autoNumericOptionsVND)
    new AutoNumeric(this.maxLoanTarget, autoNumericOptionsVND)
    new AutoNumeric(this.limitFieldTarget, autoNumericOptionsVND)
  }

  updateEquity () {
    const equity = this.propertyPrice() - this.amount()
    AutoNumeric.getAutoNumericElement(this.equityTarget).set(equity)
  }

  updateLimitField () {
    const limitField = this.grossIncome() * 3.5
    AutoNumeric.getAutoNumericElement(this.limitFieldTarget).set(limitField)
  }

  updateLoanValue () {
    const loanValue = Number(this.amount() / this.propertyPrice() * 100)
    this.loanValueTarget.value = Number(loanValue).toFixed(0)+'%'
  }

  updateMaxLoan () {
    const maxLoan = this.amount() / 3.5
    AutoNumeric.getAutoNumericElement(this.maxLoanTarget).set(maxLoan)
  }

  loanValue () {
    return parseInt(this.loanValue.value || 0)
  }


  warning () {
    if (this.limitField() < this.amount()) {
      this.warningTarget.style.display = "inline-block "
    } else {
      this.warningTarget.style.display = "none"
    }
  }

  success () {
    if (this.limitField() > this.amount()) {
      this.successTarget.style.display = "inline-block"
    } else {
      this.successTarget.style.display = "none"
    }
  }

  turnRed () {
    if (this.loanValue() > 100) {
      this.turnRedTarget.style.display = "inline-block"
    } else {
      this.turnRedTarget.style.display = "none"
    }
  }

  unformatAmountFields () {
    AutoNumeric.getAutoNumericElement(this.equityTarget).formUnformat()
  }
  
  equity () {
    return AutoNumeric.getAutoNumericElement(this.equityTarget).getNumber() 
  }

  grossIncome () {
    return AutoNumeric.getAutoNumericElement(this.grossIncomeTarget).getNumber()
  }

  limitField () {
    return AutoNumeric.getAutoNumericElement(this.limitFieldTarget).getNumber() 
  }

  amount () {
    return AutoNumeric.getAutoNumericElement(this.amountTarget).getNumber()
  }

  maxLoan () {
    return AutoNumeric.getAutoNumericElement(this.maxLoanTarget).getNumber()
  }

  propertyPrice () {
    return AutoNumeric.getAutoNumericElement(this.propertyPriceTarget).getNumber()
  }
} 
    
